/*
--------------------------------------------------------------------------------
GENARAL/WEBFONTS
Webfonts
Hier die verwendeten lokalen Webfonts eintragen
--------------------------------------------------------------------------------
*/

  // Rationale
@include font-face("Rationale", "../font/rationale-v8-latin-regular", normal, normal);
