/*
-------------------------------------------------
FIGURE
HTML-Element figure
-------------------------------------------------
*/

$bg-color-picture-frame: #4f4f4f !default;
// TODO Variablen verwenden und einsetzen
// Bilder vertikal und horizontal zentrieren, ohne sie zuzuschneiden, Rahmen und Schatten TODO: moderner gestalten
// Look Bilderrahmen wie von LR generiert
%picture_frame {
  background-color:#4f4f4f;
  border-right: 1px solid #3f3f3f;
  border-bottom: 1px solid #3f3f3f;
  width: 250px;
  height: 250px;
  line-height: 248px;
  text-align: center;
  img {
    vertical-align:middle;
    background-color: transparent;
    padding: 0;
    border: solid 1px #323232;
    box-shadow:  4px 4px 4px #333;
  }
}

// Look Bilderrahmen wie von LR generiert mit Bildunterschrift
%picture_frame--caption {
  @extend %picture_frame;
  height: 270px;
  figcaption {
    h2 {
      font-size: 0.75rem;
      font-weight: normal;
    	line-height: 1;
      padding: 0;
      margin: 0;
    }
  }
}
