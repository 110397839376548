/*
-------------------------------------------------
MODUL/PRIVATE MOD-LOGO
Gestaltung Logo-Modul und Logo
-------------------------------------------------
*/

$screen-min: 20rem !default;
$screen-max: 64rem !default; // TODO Unterschiede mit vw und vwmin
$logofont-min: 3.6rem !default;
$logofont-max: 5rem !default;

.logo {
  font-family:'Rationale', sans-serif;
  @include fluid-typo(font-size, $screen-min, $screen-max, $logofont-min / 2.35, $logofont-max / 2.35);
  line-height: 1.2;
  text-align: center;
	text-shadow: 1px 1px 1px #000;
  @include small {
    text-align: left;
    margin-right: 2rem;
  }
  span {
    @include fluid-typo(font-size, $screen-min, $screen-max, $logofont-min, $logofont-max);
    display: block;
  }
  a {
    color:rgba(255, 255, 255, 0.5);
    text-decoration: none;
  }
  &.logo_hide {
    @include hide;
  }
}
