/*
--------------------------------------------------------------------------------
LAYOUT/HEADER-THEME
Gestaltung der Layout Header
--------------------------------------------------------------------------------
*/

#footer {
  text-align: right;
  margin-top: $baseline;
  // align-self: end;
  p {
    margin: 0;
  }
  a {
    @include link-color(
      $color-link-link: $color-alternative,
      $color-link-visited: $color,
      $color-link-hover: lighten($color-alternative, 10%),
      $color-link-focus: $color-alternative,
      $color-link-active: $color-alternative
    )
  }
}
