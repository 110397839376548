// Breakpoints
$b-xxsmall : 20rem !default;  // 320px smartphone (portrait)
$b-xxsmall-max : calc(30rem - 1px) !default;  // 479px smartphone (portrait)
$b-xsmall  : 30rem!default; // 480px smartphone (landscape)
$b-xsmall-max  : calc(37.5rem - 1px) !default; // 599px smartphone (landscape)
$b-small   : 37.5rem !default; // 600px small tablet
$b-small-max   : calc(48rem - 1px) !default; // 767px small tablet
$b-medium  : 48rem !default; // 768px tablet (portrait)
$b-medium-max  : calc(64rem - 1px) !default; // 1023px tablet (portrait)
$b-large   : 64rem !default; // 1024px tablet (landscape)
$b-large-max   : calc(90rem - 1px) !default; // 1493px tablet (landscape)
$b-xlarge  : 90rem !default;// 1440px desktop
$b-xlarge-max  : calc(100rem - 1px) !default;// 1599 desktop
$b-xlarge-plus  : 100rem !default;// 1600px desktop
$b-xxlarge : 120rem !default; // 1920px

// Hightresolution
$retina        : "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)" !default;

// ab einer Breite
@mixin xxsmall {
  @media only screen and (min-width: #{$b-xxsmall}) {
    @content;
  }
}

@mixin xxsmall-hidpi($ratio: 1.3) {
  @media only screen and (min-width: #{$b-xxsmall}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xxsmall}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xxsmall}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-xxsmall}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-xxsmall}) and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}

@mixin xsmall {
  @media only screen and (min-width: #{$b-xsmall}) {
    @content;
  }
}

@mixin xsmall-hidpi($ratio: 1.3) {
  @media only screen and (min-width: #{$b-xsmall}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xsmall}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xsmall}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-xsmall}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-xsmall}) and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}

@mixin small {
  @media only screen and (min-width: #{$b-small}) {
    @content;
  }
}

@mixin small-hidpi($ratio: 1.3) {
  @media only screen and (min-width: #{$b-small}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-small}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-small}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-small}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-small}) and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: #{$b-medium}) {
    @content;
  }
}

@mixin medium-hidpi($ratio: 1.3) {
  @media only screen and (min-width: #{$b-medium}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-medium}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-medium}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-medium}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-medium}) and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: #{$b-large}) {
    @content;
  }
}

@mixin large-hidpi($ratio: 1.3) {
  @media only screen and (min-width: #{$b-large}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-large}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-large}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-large}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-large}) and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}

@mixin xlarge {
  @media only screen and (min-width: #{$b-xlarge}) {
    @content;
  }
}

@mixin xlarge-hidpi($ratio: 1.3) {
  @media only screen and (min-width: #{$b-xlarge}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xlarge}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xlarge}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-xlarge}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-xlarge}) and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}

@mixin xlarge-plus {
  @media only screen and (min-width: #{$b-xlarge-plus}) {
    @content;
  }
}

@mixin xlarge-plus-hidpi($ratio: 1.3) {
  @media only screen and (min-width: #{$b-xlarge-plus}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xlarge-plus}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xlarge-plus}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-xlarge-plus}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-xlarge-plus}) and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}

@mixin xxlarge {
  @media only screen and (min-width: #{$b-xxlarge}) {
    @content;
  }
}

@mixin xxlarge-hidpi($ratio: 1.3) {
  @media only screen and (min-width: #{$b-xxlarge}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xxlarge}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xxlarge}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-xxlarge}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-xxlarge}) and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}

//auschliesslich ein Bereich
@mixin only-xxsmall {
  @media only screen and (min-width: #{$b-xxsmall}) and (max-width: #{$b-xxsmall-max}) {
    @content;
  }
}

@mixin only-xsmall {
  @media only screen and (min-width: #{$b-xsmall}) and (max-width: #{$b-xsmall-max}) {
    @content;
  }
}

@mixin only-small {
  @media only screen and (min-width: #{$b-small}) and (max-width: #{$b-small-max}) {
    @content;
  }
}

@mixin only-medium {
  @media only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) {
    @content;
  }
}
@mixin only-medium-hidpi($ratio: 1.3) {
  @media only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}

@mixin only-large {
  @media only screen and (min-width: #{$b-large}) and (max-width: #{$b-large-max}) {
    @content;
  }
}

@mixin only-xlarge {
  @media only screen and (min-width: #{$b-xlarge}) and (max-width: #{$b-xlarge-max}) {
    @content;
  }
}
