@charset 'UTF-8';
// muss in der ersten Zeile des zu compilierenden scss-Datei stehen
/*
=========================================================
Style-CSS
Projekt: Fotos-Lange
Autor: Maren Lange
=========================================================
*/

// Libraries und Normalisierung
@import 'bourbon';
@import 'normalize';

// TOOLS - Helferchen
@import 'tools/functions/strip-unit';
@import 'tools/mixins/media_queries';
@import 'tools/mixins/fluid_typo';
@import 'tools/mixins/link_color';
@import 'tools/mixins/hide';
@import 'tools/mixins/vertical_rhythm';

// VARIABLE
@import 'setting';

// GENERALS - Basiseinstellungen
@import 'general/webfont';
// @import 'general/normalize';
@import 'general/box_sizing';
@import 'general/contao_reset';

@import 'general/base';

// COMPONENTS - Komponenten der Website
@import 'components/headline';
@import 'components/link';
@import 'components/figure';
@import 'components/nav';
@import 'components/_logo';
@import 'components/mod_article';
@import 'components/ce_gallery';

// VENDOR - Styles fuer Plugins
// @import 'vendor/nivo_lightbox';

// LAYOUT
@import 'layout/wrapper';
@import 'layout/header';
@import 'layout/container';
@import 'layout/main';
@import 'layout/footer';
