/*
-------------------------------------------------
GALLERY
Contentelement ce_gallery
-------------------------------------------------
*/


%gallery {
    list-style: none;
    list-style-image: none;
    padding: 0;
    margin: 1rem 0 0;
    figure {
      margin: 0;
    }
    li {
      margin: 0;
      padding: 0;
    }
}
.list_albums,
.album_preview {
  @extend %gallery;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  justify-content: center;
  @supports not (display: grid) {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
  }
}
.album_preview__item {
  @extend %picture_frame;
}
.list_albums__item {
  @extend %picture_frame--caption;
}
// grid im IE und edge funktioniert nicht
.ie,
.edge {
  .list_albums {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
  }
}
