/*
--------------------------------------------------------------------------------
GENERAL/CONTAO_RESET
von Contao verwendete Klassen
 --------------------------------------------------------------------------------
 */
// Float Klassen
.float_left {
	float: left;
}

.float_right {
	float: right;
}

// Clear Floats
.block {
	overflow: hidden;
}

// Micro clearfix: [Nicolas Gallagher]
// (http://nicolasgallagher.com/micro-clearfix-hack/)
// For modern browsers
 // 1. The space content is one way to avoid an Opera bug when the
 //    contenteditable attribute is included anywhere else in the document.
 //    Otherwise it causes space to appear at the top and bottom of elements
 //    that are clearfixed.
 // 2. The use of `table` rather than `block` is only necessary if using
 //    `:before` to contain the top-margins of child elements.
.clear {
	&:before,
	&:after {
		content: ' '; //1
		display: table; //2
	}
	&:after {
		clear: both;
	}
}

// nur fuer Screenreader lesbar
// based on HTML5 Boilerplate:
// github.com/h5bp/html5-boilerplate/issues/194#issuecomment-564745
.invisible {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
	overflow: hidden;
}
