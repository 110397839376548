/*
--------------------------------------------------------------------------------
LAYOUT/HEADER-THEME
Gestaltung der Layout Header
--------------------------------------------------------------------------------
*/

#header {
  margin-bottom: $baseline;
  .inside {
    @include small {
      display: flex;
      flex-flow: wrap;
    }
  }
  .nav-main {
    @include small {
      flex: 1 0 auto;
    }
  }
}
