// Settings Project
// projektbezogene Variable
//------------------------------------------------------------------------------

//GENERAL/BASE
// Typography global
$sans-serif: append('Lucida Sans Unicode', $lucida-grande, comma);
// $sans-serif: $helvetica;
// $sans-serif: $verdana;
// $sans-serif: append('Roboto', $verdana, comma); // ersten Wert wird angehaengt

$font-weight: 400;
$font-weight-headline: 400;

$screen-min: 20rem;
$screen-max: 37.5rem;
$basefont-min: 0.8rem;
$basefont-max: 1rem;

// Color-Schema
$dark-gray: #323232;
$gray     : #999;
$silver   : #bebebe;
$cactus   : #5a7a5a;

// Background global
$bg-color            : $dark-gray;

// Colors global
$color:                   $gray;
$color-alternative:       $silver;
// $color-invers:            #fff;
$color-accent:            $cactus;

// Link Colors global (einkommentieren nur wenn andere Zuordnug als default)
// $color-link:          $color-accent;
// $color-link-link:     $color-link;
// $color-link-visited:  gray;
// $color-link-hover:    darken($color-accent, 30%);
// $color-link-focus:    $color-link-hover;
// $color-link-active:   $color-link-hover;

//Bordes global
// $border-width:             1px;
// $border-color:             $gray-standard;
// $border:                   $border-width solid $border-color;
// $border-color-alternative: $color-alternative;
// $border-alternative:       rem($border-width) solid $border-color-alternative;
// $border-color-invers:      $color-invers;
// $border-invers:            rem($border-width) solid $border-color-invers;
// $border-color-accent:      $color-accent;
// $border-accent:            rem($border-width) solid $border-color-accent;
// $border-radius:            5px;

// Shadows
// $box-shadow: 0px 0px 8px rgba(30,31,70,0.5);
// $box-shadow-light: 0px 3px 2px -2px rgba(51,51,3,0.05);
// $box-shadow-light: 0px 3px 2px -2px rgba(51,51,3,0.05);
// $box-shadow: 0 0 5px $gray !default;

// COMPONENTS/NAVIGATION
// $color-link-nav: $color-alternative;
$color-link-nav-current: $color-alternative;
$color-link-nav-current: $color-accent;
$bg-nav: transparent; // verwendet entweder fuer umgebendes mod_navigation oder fuer ul

// LAYOUT
$min-width:  20rem; //320px
// $max-width:  1600px;
// $min-height: 100vh;
// $content-width: 750px;
// LAYOUT/HEADER
