//unsichtbare Element, aber für Screenreader sichtbar (Übernahme aus Contao Framework bzw. von html5-boilerplate)


@mixin hide ($hide: true) {
  @if $hide {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    overflow: hidden;
  }
  @else {
    position: static;
    clip: auto;
    width: auto;
    height: auto;
    padding: 0;
    border: 0;
    margin: 0;
    overflow: visible;
  }
}
