
@mixin link-color(
  $color-link-link: $color-accent,
  $color-link-visited: $color-alternative,
  $color-link-hover: darken($color-accent, 10%),
  $color-link-focus: $color-link-hover,
  $color-link-active: $color-link-hover
  )
  {
  &:link {
    color: $color-link-link;
  }

  &:visited {
    color: $color-link-visited;
  }

  @if ($color-link-focus or $color-link-hover) {
    @if ($color-link-focus == $color-link-hover) {
      &:focus,
      &:hover {
        color: $color-link-hover;
      }
    }
    @else {
      &:focus {
        color: $color-link-focus;
      }

      &:hover {
        color: $color-link-hover;
      }
    }
  }

  &:active {
    color: $color-link-active;
  }
}
