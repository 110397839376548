/*
-------------------------------------------------
LINKs
HTML-Element a
-------------------------------------------------
*/

$color-alternative: #000 !default;

.backlink {
  text-align: right;
  a {
    @include link-color($color-link-visited: $color-accent);
    display: block;
    padding: $baseline 1rem;
  }
}
