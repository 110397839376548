/*
--------------------------------------------------------------------------------
LAYOUT/WRAPPER
Gestaltung des Gesamt Layouts

--------------------------------------------------------------------------------
*/

$min-width: 20rem !default;
$max-width: 120rem !default;

#wrapper {
  display: flex;
  flex-flow: column;
  // align-content: space-between;
  min-width: $min-width;
  max-width: $max-width;
  min-height: 100vh;
  padding: $baseline;
  margin: 0 auto;
  > #container {
    flex: 1;
  }
}
