/*
--------------------------------------------------------------------------------
NAVIGATIONEN

--------------------------------------------------------------------------------
*/
$color                 : $gray !default;
$color-link-nav        : $color !default;
$color-link-nav-current: $color-accent !default;
$baseline              : 1.5 !default;

%nav {
  ul {
    list-style: none;
    list-style-image: none;
    padding: 0;
    margin: 0;
  }
  a {
    text-decoration: none;
  }
  a,
  strong {
    display: block;
  }
}

// Hauptnavigation
.nav-main {
  @include hide;
  @extend %nav;
  @include small {
    @include hide(false);
  }
  .level_1 {
    display: flex;
    flex-flow: column;
    @include small {
      flex-flow: row;
      justify-content: space-between;
    }
    @include large {
      justify-content: flex-end;
    }
    > li {
      > a,
      > strong {
        text-align: center;
        padding: $baseline 1rem;
        @include small {
          text-align: right;
        }
      }
      > a {
        color: $color-link-nav;
      }
      > a:hover,
      > .trail,
      > strong {
        color: $color-link-nav-current;
      }
    }
  }
  // mobile Version
  &.nav_show {
    @include hide(false);
    margin-top: 2rem;
  }
  .no-js & {
    @include hide(false);
    margin-top: 2rem;
  }
}

// button mobile Navigation
.btn_nav_mobile {
  text-align: right;
  margin-bottom: 2rem;
  @include small {
    @include hide; // auf großen Bildschirmen ausblenden
  }
  .icon_btn_menu {
    fill: $color;
    @include hide(false);
    width: 2rem;
    height: 2rem;
  }
  .icon_btn_close {
    fill: $color;
    @include hide;
  }
  a {
    display: block;
  }
  &.btn_active {
    .icon_btn_menu {
      @include hide;
    }
    .icon_btn_close {
      @include hide(false);
      width: 2rem;
      height: 2rem;
    }
  }
  .no-js & {
    @include hide; // bei javascript aus ausblenden
  }
}
