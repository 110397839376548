/*
-------------------------------------------------
HEADLINES
HTML-Element h1 bis h6
-------------------------------------------------
*/



.headline--centered {
  text-align: center;
}
