/*
-------------------------------------------------
GENERAL/BASE
Basis Einstellungen
-------------------------------------------------
*/

//Typography default
$sans-serif:           $verdana !default;
$serif:                $georgia !default;
$font-family:          $sans-serif !default;
$font-family-headline: $font-family !default;
$font-family-print:    $serif !default;

$font-weight:          400 !default;
$font-weight-headline: 700 !default;

$baseline: 1.5rem !default; //TODO $baseline in Abhaengigkeit von Bildschirmbreite

$screen-min: 20rem !default;
$screen-max: 64rem !default; // TODO Unterschiede mit vw und vwmin untersuchen
$basefont-min: 0.8rem !default;
$basefont-max: 2rem !default;

$h1: 2rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: 1rem !default;
$h6: 1rem !default;

// Color-Schema default
$nearly-white: #e6e6e6 !default;
$gray: #333 !default;
$red:  #ff0000 !default;
$blue: #477dca !default;
// Transparenzen default
$transparency:   0.8 !default;
$transparency-medium: 0.5 !default;
// Background global default
$bg-color:                 #fff !default;
$bg-color-alternative:     $nearly-white !default;
$bg-color-invers:          $gray !default;
$bg-color-accent:          $blue !default;
$bg:                       $bg-color !default;
$bg-invers:                $bg-color-invers !default;
$bg-alternative:           $bg-color-alternative !default;
$bg-accent:                $bg-color-accent !default;
// Colors global default
$color:                    $gray !default;
$color-alternative:        #000 !default;
$color-invers:             $nearly-white !default;
$color-accent:             $blue !default;
// Link Colors global default
$color-link:         $color-accent !default;
$color-link-link:    $color-link !default;
$color-link-visited: lighten($color, 40%) !default;
$color-link-hover:   darken($color-accent, 15%) !default;
$color-link-focus:   $color-link-hover !default;
$color-link-active:  $color-link-hover !default;
// Bordes global default
$border-width:             1px !default;
$border-color:             $color !default;
// $border:                   rem($border-width) solid $border-color !default;
$border-color-alternative: $color-alternative !default;
// $border-alternative:       rem($border-width) solid $border-color-alternative !default;
$border-color-invers:      $color-invers !default;
// $border-invers:            rem($border-width) solid $border-color-invers !default;
$border-color-accent:      $color-accent !default;
// $border-accent:            rem($border-width) solid $border-color-accent !default;
$border-radius:            5px !default;
// Shadows global default
$box-shadow: 0px 0px 8px rgba(30,31,70,0.5) !default;
$box-shadow-light: 0px 3px 2px -2px rgba(51,51,3,0.05) !default;
//Schatten
$box-shadow: 0 0 5px $gray !default;

//HTML
html {
  @include fluid-typo(font-size, $screen-min, $screen-max, $basefont-min, $basefont-max);
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  height: 100%;
}

//BODY
body {
  background: $bg;
  color: $color;
  font-family: $sans-serif;
  line-height: strip-unit($baseline);
  min-height: 100%;

  @media print {
    background: transparent none;
    font-family: $serif;
    font-size: 12pt;
    line-height: 1.3;
  }
}

//TYPOGRAPHY
// Ueberschriften
h1,
h2,
h3,
h4,
h5,
h6  {
  @if not ($font-family == $font-family-headline) {
    font-family: $font-family-headline;
  }
  @if not ($font-weight-headline == 400) {
    font-weight: $font-weight-headline;
  }
  padding: ($baseline * 3 / 4) 0 ($baseline / 4);
  margin: 0 0 $baseline;
}
h1 {
  font-size: $h1;
  line-height: $baseline / $h1;
}
h2 {
  font-size: $h2;
  line-height: $baseline / $h2;
}
h3 {
  font-size: $h3;
  line-height: $baseline / $h3;
}
h4 {
  font-size: $h4;
  line-height: $baseline / $h4;
}
h5 {
  font-size: $h5;
  line-height: $baseline / $h5;
}
h6 {
  font-size: $h6;
  line-height: $baseline / $h6;
}
@media print {
  h1,
  h2,
  h3 {
    margin: 0 0 9pt;
    padding: 0;
    // @include ml-break-after;
  }
  h1 {
    font-size: 18pt;
  }
  h2 {
    font-size: 16pt;
  }
  h3 {
    font-size: 14pt;
  }
  h4,
  h5,
  h6 {
    font-size: 12pt;
  }
}
//Fliesstext
%font {
  @if not($font-weight == 400) {
    font-weight: $font-weight;
    margin-bottom: $baseline;
  }
  line-height: $baseline;
  @media print {
    font-size: 12pt;
    margin: 0 0 9pt;
  }
}
p {
  @extend %font;
  margin: 0 0 $baseline;
//   @include xsmall {
//     margin: 0 0 $baseline-mobile;
//   }
}
ul {
  margin: $baseline 0;
  // @include xsmall {
  //   margin: $baseline-mobile 0;
  // }
}
address {
  font-style: normal;
  margin: 0 0 $baseline;
  // @include xsmall {
  //   margin: 0 0 $baseline-mobile;
  // }
}
//Text-Links
p {
  a {
    @include link-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

//BILDER
img,
picture {
  height: auto;
  max-width: 100%;
  // @media print {
  //   @include ml-break-inside;
  // }
}
figure {
  position: relative;
  // margin: 0 0 ($baseline / 2);
  // @include small {
  //   margin: 0 0 ($basefont-mobile / 2);
  // }
}
figcaption {
  // font-size: $small;
  // line-height: $baseline / $small;
  // @include small {
  //   font-size: $small;
  //   line-height: $baseline / $small;
  // }
}
