/*
-------------------------------------------------
LAYOUT/LAYOUT_EINSPALTIG
einspaltiges Layout
Aufbau mit Flexbox
sticky-footer
-------------------------------------------------
*/
#main {
  .inside {
    margin: 2 * $baseline 0;
  }
}
